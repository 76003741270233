import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as Path from '@mdi/js';
import Icon from '@mdi/react';
import HeaderLanguage from './HeaderLanguage';
import HeaderLogo from './HeaderLogo';
import HeaderNavigation from './HeaderNavigation';
import HeaderToggle from './HeaderToggle';
import '../styles/header.css';

function Header({
  language,
  setLanguage,
}) {
  const [open, setOpen] = useState(false);
  return (
    <header className="header">
      <div className="ribbon">
        <div className="container">
          <div className="locations hide">
            <div className="left">
              <Icon path={Path.mdiMapMarker} size={1} />
            </div>
            <div className="right">
              <a target="_blank" rel="noopener noreferrer" href="https://www.google.com/maps/place/Livcar+Rent+a+car/@45.799275,15.999117,17z/data=!4m5!3m4!1s0x0:0xaee23418e1c308d9!8m2!3d45.799252!4d15.9991117?hl=en">
                <span> Zagreb: Hotel Livris | Rapska 12</span>
              </a>
              <a target="_blank" rel="noopener noreferrer" href="https://www.google.com/maps/place/Livcar+Rent+a+car/@42.5583465,18.2619609,19z/data=!3m1!4b1!4m6!3m5!1s0x134c13bcf6086533:0x250a7983ecc205ca!8m2!3d42.5583465!4d18.2619609!16s%2Fg%2F11wws4hhd2">
                <span>Dubrovnik: Zračna luka Dubrovnik | Dobrota 24, 20213 Močići</span>
              </a>
            </div>
          </div>
          <a href="tel:+385 98 633 893">
            <Icon path={Path.mdiPhoneClassic} size={1} />
            <span>+385 98 633 893</span>
          </a>
          <a href="mailto:info@livcar.hr">
            <Icon path={Path.mdiEmail} size={1} />
            <span>info@livcar.hr</span>
          </a>
          <HeaderLanguage setLanguage={setLanguage} language={language} />
        </div>
      </div>
      <div className="container">
        <HeaderToggle icon={Path.mdiMenu} onClick={() => setOpen(true)} />
        <HeaderLogo />
        {/* eslint-disable-next-line max-len */}
        <div aria-label="Backdrop" role="button" className={`backdrop mobile ${open && 'open'}`} onClick={() => setOpen(false)} />
        <HeaderNavigation open={open} setOpen={setOpen} />
      </div>
    </header>
  );
}

Header.propTypes = {
  language: PropTypes.string.isRequired,
  setLanguage: PropTypes.func.isRequired,
};

export default Header;
